import { Card, CardContent, CircularProgress, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import ChangePassword from 'pages/common/ChangePassword';
import LogOut from 'pages/common/Logout';
import Profile from 'pages/common/Profile';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllListData } from 'services/CommonServices';
import { MASTER_ENDPOINT } from 'utils/Constant';

import useStyles from '../../private/PrivateStyle';

export const Myprofile: React.FC<any> = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [list, setList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  console.log(list, 'ListprrRecruiter');
  console.log(list[0], 'Recruiter index');
  useEffect(() => {
    getVersionHistory();
  }, []);

  const getVersionHistory = () => {
    getAllListData(
      `${MASTER_ENDPOINT.VersionHistory}?order=desc&sortby=versionId`,
    )
      .then((resp: any) => {
        setList([resp?.data?.[0]]);
        setLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Typography
      component={'div'}
      style={{
        background: '#f5f5f5',
        height: '100vh',
        paddingTop: loading ? '0px' : '50px',
      }}>
      {loading ? (
        <Typography
          component={'div'}
          sx={{
            alignItems: 'center',
            // alignContent: 'center',
            display: 'flex',
            height: 'calc(100% - 50px) !important',
            // height: '90%',
            justifyContent: 'center',
            margin: 'auto 0px',
            textAlign: 'center',
            width: '100%',
          }}>
          <CircularProgress sx={{ color: '#340000' }} size={60} />
        </Typography>
      ) : (
        <Grid container>
          <Grid item md={12}>
            <Typography component={'div'} className={classes.profilecard}>
              <Typography component="h6">Personal Info</Typography>
              <Profile />
            </Typography>
            <Typography component={'div'} className={classes.profilecard}>
              <Typography component="h6">More Options</Typography>
              <Card sx={{ boxShadow: 0 }}>
                <CardContent>
                  <Typography component={'div'}>
                    <ul className={classes.optiondetails}>
                      <li>
                        <ChangePassword />
                      </li>
                      <li>Settings</li>
                      <li>Privacy Policy</li>
                      <li>Terms & Conditions</li>
                      <li className={classes.logout}>
                        <LogOut />
                        <span
                          onClick={() => navigate('/version-history')}
                          style={{ color: '#292929', cursor: 'pointer' }}>
                          {list.length ? list[0].versionId : ''}
                        </span>
                      </li>
                    </ul>
                  </Typography>
                </CardContent>
              </Card>
            </Typography>
          </Grid>
        </Grid>
      )}
    </Typography>
  );
};

export default Myprofile;
